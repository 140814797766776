<template>
  <div class="partners-page">
    <div class="container">
      <div class="partners-page__wrapper">
        <div class="head">
          <h1>Партнеры </h1>
        </div>
        <div class="partner-list" ref="pageContent">
          <div class="item" v-for="item in items" :key="item.title">
            <span class="text">{{item.title}}</span>
            <span class="icon"><img :src="item.icon" alt="icon"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "partners",
  data() {
    return {
      items: [
        {'title': 'Moldova Esports Federation', 'icon': 'https://krsk.sgl.one/storage/uploads/IAu2g4RTrzgj7WWhgF4SDWjnKOvaMPdGw6iXTtlP.png'},
        {'title': 'Qazaq cybersport federation', 'icon': 'https://krsk.sgl.one/storage/uploads/cphzaiARZcXdgHajs5C89ReMB45m4I0LSEBwjm6d.png'},
        {'title': 'МосОблСпорт', 'icon': 'https://krsk.sgl.one/storage/uploads/cd1izjSvy9g8eAoZFo2QB3TleCYxkLsZTg75VVDD.png'},
        {'title': 'АКАДО Телеком', 'icon': 'https://krsk.sgl.one/storage/uploads/uSXfhiFFGU9feK4sHsEurm2cRFwphlg0GOaUWq0Y.png'},
        {'title': 'Ассоциация Белорусская федерация футбола', 'icon': 'https://krsk.sgl.one/storage/uploads/0H0DkYIUtcXuBHFi2hoi97EPSHqStNfDXjFt8dKB.png'},
        {'title': 'БК Локомотив-Кубань', 'icon': 'https://krsk.sgl.one/storage/uploads/glWC2Xf7ICNOt8DNVbyCTxqR1hsEkdBbkP287lzF.png'},
        {'title': 'Единая лига ВТБ', 'icon': 'https://krsk.sgl.one/storage/uploads/lcsfGLuDCaX3S4ivS8DT9izZuSgWxsYPsQoL0n3Y.png'},
        {'title': 'ЗАО «ТТК»', 'icon': 'https://krsk.sgl.one/storage/uploads/7w6JvYcXgcALvUGKGFbzXnxee3TP2HoOKEt59Ib6.png'},
        {'title': 'Континентальная хоккейная лига', 'icon': 'https://krsk.sgl.one/storage/uploads/xQpFx1qljUDXCG8K7h2TlvcoFVrdUS8noh57SmUe.png'},
        {'title': 'Красспорт', 'icon': 'https://krsk.sgl.one/storage/uploads/cd1izjSvy9g8eAoZFo2QB3TleCYxkLsZTg75VVDD.png'},
        {'title': 'Лига ставок', 'icon': 'https://krsk.sgl.one/storage/uploads/MDFWHBsUNpOfnXhyr6Ba2lILy9Jflroa2MtKTJyd.png'},
        {'title': 'Министерство Спорта и Туризма Республики Беларусь', 'icon': 'https://krsk.sgl.one/storage/uploads/3OVnEDmCiSsWq2fOEn8Akz4eabbCNgpaWG5XRFAj.png'},
        {'title': 'Министерство цифрового развития, связи и массовых коммуникаций РФ', 'icon': 'https://krsk.sgl.one/storage/uploads/KtNW1TRqzBF6PkyybMP1u777rBALKuN0zp6PYLx4.png'},
        {'title': 'FONBET', 'icon': 'https://krsk.sgl.one/storage/uploads/MDFWHBsUNpOfnXhyr6Ba2lILy9Jflroa2MtKTJyd.png'},
        {'title': 'Правительство Кузбасса', 'icon': 'https://krsk.sgl.one/storage/uploads/b7Q4u57dfNH7wXI5oPyqXTW0N5EKkzVDr5ZKETqt.png'},
        {'title': 'РЖД', 'icon': 'https://krsk.sgl.one/storage/uploads/0CQLHnC4s6nRlRyu0WxcV5FeVU6G5GOsSyS6NN4P.png'},
        {'title': 'Сервис voka.tv', 'icon': 'https://krsk.sgl.one/storage/uploads/paMUiZ6HPxriA0fJkVCQrud1wNDJTYOVZ1afF4z6.png'},
        {'title': 'Союз ММА России', 'icon': 'https://krsk.sgl.one/storage/uploads/rKFXoFwizU9dzYotTaMoYuO5K4zW2ZzSIasIoKjk.png'},
        {'title': 'Федерация Киберспорта Азербайджана', 'icon': 'https://krsk.sgl.one/storage/uploads/hXTnRsf6KJe6VqnOTWEq5JlW08kinYDCx8aHd7Z2.png'},
        {'title': 'Федерация киберспорта Армении', 'icon': 'https://krsk.sgl.one/storage/uploads/Gd8YuBnnyNQfhWvpmpJhTeud6oQcTmqgtlUJCajg.png'},
        {'title': 'Федерация хоккея России', 'icon': 'https://krsk.sgl.one/storage/uploads/Dj36QgXhwZczUMCoqdQwzi73BUZuFGJp7H637GvN.png'},
        {'title': 'Winline', 'icon': 'https://krsk.sgl.one/storage/uploads/MDFWHBsUNpOfnXhyr6Ba2lILy9Jflroa2MtKTJyd.png'},
        {'title': 'YaLLa Esports', 'icon': 'https://krsk.sgl.one/storage/uploads/sDGAKQsuSAcGxnpCaAwwO77vPYY4AFZLX7nw6iYd.png'},
        {'title': 'Федерация регби России', 'icon': 'https://krsk.sgl.one/storage/uploads/ymWpZS0e5mzWgmjmj8YF8cBKivqw6Xi7wiJsIlGw.png'},
        {'title': 'Федерация футбола Казахстана', 'icon': 'https://krsk.sgl.one/storage/uploads/RzwDd9NZEHLazf0ZX3DkVYx5ZAIO9OOQshz1miwL.png'},
        {'title': 'Федерация футбола Молдовы', 'icon': 'https://krsk.sgl.one/storage/uploads/UjNJP3bvYApJ1JHIw6ZPkJldbik1ncmGN80i0olF.png'},
        {'title': 'Федерация электронного компьютерного спорта Узбекистана', 'icon': 'https://krsk.sgl.one/storage/uploads/pg5OnUzNDeLluVmZnXEkQJ657sUVvTCkynlrdaHc.png'},
      ]
    }
  }
}
</script>

<style scoped>

</style>